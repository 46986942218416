import Vue from 'vue'
import VueRouter from 'vue-router'
import staticRoutes from './routes' //静态路由

Vue.use(VueRouter)

export default function (/* { store, ssrContext } */) {
  const Router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
      if (to.name === from.name) {
        return false
      } else {
        return { x: 0, y: 0 }
      }
    },
    routes: staticRoutes,
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE
  })
  return Router
}

export function transRoutes(asyncRouter) {
  return asyncRouter.map((route) => {
    const routesCopy = { ...route } //浅拷贝路由
    if (typeof routesCopy.component === 'string') {
      let path = `${routesCopy.component}.vue`
      if (routesCopy.path === '/dashboard') {
        routesCopy.component = () => import('layouts/' + path)
      } else {
        routesCopy.component = () => import('pages/' + path)
      }
    } else {
      routesCopy.component = { render: (h) => h('router-view') }
    }
    if (routesCopy.children && routesCopy.children.length) {
      //如果路由存在子路由则递归调用transRoutes函数处理子路由
      routesCopy.children = transRoutes(routesCopy.children)
    }
    return routesCopy
  })
}
