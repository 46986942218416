//自定义指令
export default ({ app, router, store, Vue }) => {
  Vue.directive('hasPermi', { //hasPermi自定义指令的名称
    inserted(el, binding) { //el指令所绑定的DOM元素 binding元素的信息
      const { value } = binding
      const allPermission = '*:*:*' //拥有所有权限的标识
      const permissionList = store.getters['UserStore/getLoginUserInfo']?.Perms //获取当前用户的权限列表
      if (Array.isArray(value) && value.length > 0) {
        const hasPermission = permissionList.some((item) => {
          return allPermission === item || value.includes(item)
        })
        if (!hasPermission) {
           el.remove() //移除节点
        }
      } else {
        throw new Error('请设置操作权限标签值')
      }
    }
  })
}
