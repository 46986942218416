import { axiosInstance as axiosAssembly } from '../../boot/axios-assembly'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // 獲取需要添加工單訂單信息
    GetOrderInfo({ commit, rootGetters }, request) {
      return axiosAssembly
        .post('GetOrderInfo', {
          OrderId: request.OrderId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values.Data)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 獲取工班主管
    GetWorkMasterList({ commit, rootGetters }, request) {
      return axiosAssembly
        .post('GetWorkMasterList')
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values.Data)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 获取业务人员的信息
    BusinessList({ commit, rootGetters }, request) {
      return axiosAssembly
        .post('BusinessList')
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values.Data)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 添加工班項目
    SetAssemblyOrder({ commit, rootGetters }, request) {
      return axiosAssembly
        .post('SetAssemblyOrder', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 导出工班订单
    ExportAssemblyOrderExcel({ commit, rootGetters }, request) {
      return axiosAssembly
        .post(
          'ExportAssemblyOrderExcel',
          {
            ...request
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 獲取工班項目數據列表
    GetAssemblyOrderList({ commit, rootGetters }, request) {
      return axiosAssembly
        .post('GetAssemblyOrderList', {
          ...request
        })
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 獲取工班項目數據列表
    GetAssemblyOrderInfo({ commit, rootGetters }, request) {
      return axiosAssembly
        .post('GetAssemblyOrderInfo', {
          AssemblyOrderId: request.AssemblyOrderId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values.Data)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
      },
    // 獲取滿意度調查工班列表
    GetSatisfactionFinish({ commit, rootGetters }, request) {
        return axiosAssembly
          .post('GetSatisfactionFinish', {
            ...request
          })
          .then((response) => {
            return Promise.resolve(response.data.Values)
          })
          .catch((error) => {
            return Promise.reject(error)
          })
      },
    // 獲取滿意度調查問題表
    GetSatisfactionAnswer({ commit, rootGetters }, request) {
        return axiosAssembly
          .post('GetSatisfactionAnswer', {
            ...request
          })
          .then((response) => {
            return Promise.resolve(response.data.Values)
          })
          .catch((error) => {
            return Promise.reject(error)
          })
      }
  }
}
