import { axiosInstance as axios } from '../../boot/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // #region Quick CabinetPOST

    //获取快客柜业务案件分配信息
    GetQuickAllocationCaseInfo({ rootGetters }, request) {
      return axios
        .post('closethighway/GetQuickAllocationCaseInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SetQuickBusinessCaseInfo({ rootGetters }, request) {
      return axios
        .post('closethighway/SetQuickBusinessCaseInfo', {
          ...request
        })
        .then((response) => {
          if (!response.data.Values.RC) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetQuickBusinessStatusList({ rootGetters }, request) {
      return axios
        .post('closethighway/GetQuickBusinessStatusList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            console.log(response.data.Values)
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          console.log(error)
          return Promise.reject(error)
        })
    },
    //獲取久大經銷商數據信息
    GetQuickDealersList({ rootGetters }, request) {
      return axios
        .post('closethighway/GetQuickDealersList', {
          ...request
        })
        .then((response) => {
          if (!response.data.Values.RC) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //設置久大經銷商詳細信息
    SetQuickDealersInfo({ rootGetters }, request) {
      return axios
        .post('closethighway/SetQuickDealersInfo', {
          ...request
        })
        .then((response) => {
          if (!response.data.Values.RC) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //獲取久大經銷商訂單費用
    GetQuickDealersOrderList({ rootGetters }, request) {
      return axios
        .post('closethighway/GetQuickDealersOrderList', {
          ...request
        })
        .then((response) => {
          if (!response.data.Values.RC) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //獲取久大經銷商訂單費用詳細信息
    GetQuickDealersOrderInfo({ rootGetters }, request) {
      return axios
        .post('closethighway/GetQuickDealersOrderInfo', {
          ...request
        })
        .then((response) => {
          if (!response.data.Values.RC) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //設置久大經銷商收款確認
    SetQuickDealersOrderExpense({ rootGetters }, request) {
      return axios
        .post('closethighway/SetQuickDealersOrderExpense', {
          ...request
        })
        .then((response) => {
          if (!response.data.Values.RC) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //導出經銷商報價單
    ExportDealersPriceExcel({ rootGetters }, request) {
      return axios
        .post(
          'closethighway/ExportDealersPriceExcel',
          {
            ...request
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    QuickCabinetOrderList({ rootGetters }, request) {
      return axios
        .post('QuickCabinetOrderList', {
          ...request
        })
        .then((response) => {
          if (!response.data.Values.RC) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    QuickCabinetOrderDetail({ rootGetters }, request) {
      return axios
        .post('QuickCabinetOrderDetail', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    QuickCabinetOrderConfirm({ rootGetters }, request) {
      return axios
        .post('QuickCabinetOrderConfirm', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    QuickCabinetShipping({ rootGetters }, request) {
      return axios
        .post('QuickCabinetShipping', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    QuickCabinetFinish({ rootGetters }, request) {
      return axios
        .post('QuickCabinetFinish', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetQuickCabinetStyleList({ rootGetters }, request) {
      return axios
        .post('closetHighway/GetQuickCabinetStyleList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    AddQuickCabinetStyleInfo({ rootGetters }, request) {
      return axios
        .post('closetHighway/AddQuickCabinetStyleInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SetQuickCabinetStyleInfo({ rootGetters }, request) {
      return axios
        .post('closetHighway/SetQuickCabinetStyleInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DeleQuickCabinetStyleInfo({ rootGetters }, request) {
      return axios
        .post('closetHighway/DeleQuickCabinetStyleInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CabinetTypeAdd({ rootGetters }, request) {
      return axios
        .post('closetHighway/CabinetTypeAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('已有相同名稱的櫃型')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CabinetTypeRead({ rootGetters }, request) {
      return axios
        .post('closetHighway/CabinetTypeRead', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CabinetTypeModify({ rootGetters }, request) {
      return axios
        .post('closetHighway/CabinetTypeModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('已有相同名稱的櫃型')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CabinetTypeDelete({ rootGetters }, request) {
      return axios
        .post('closetHighway/CabinetTypeDelete', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetQuickCabinetCategoryList({ rootGetters }, request) {
      return axios
        .post('closetHighway/GetQuickCabinetCategoryList', {
          ...request
        })
        .then((response) => {
          if (!response.data.RC) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    AddQuickCabinetCategoryInfo({ rootGetters }, request) {
      return axios
        .post('closetHighway/AddQuickCabinetCategoryInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SetQuickCabinetCategoryInfo({ rootGetters }, request) {
      return axios
        .post('closetHighway/SetQuickCabinetCategoryInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DeleQuickCabinetCategoryInfo({ rootGetters }, request) {
      return axios
        .post('closetHighway/DeleQuickCabinetCategoryInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CabinetTypeList({ rootGetters }, request) {
      return axios
        .post('closetHighway/CabinetTypeList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CabinetAdd({ rootGetters }, request) {
      return axios
        .post('closetHighway/CabinetAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('櫃體重複')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('櫃體類型不存在')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CabinetRead({ rootGetters }, request) {
      return axios
        .post('closetHighway/CabinetRead', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CabinetModify({ rootGetters }, request) {
      return axios
        .post('closetHighway/CabinetModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CabinetDelete({ rootGetters }, request) {
      return axios
        .post('closetHighway/CabinetDelete', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CabinetList({ rootGetters }, request) {
      return axios
        .post('closetHighway/CabinetList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //快客訂單匯出Excel
    QuickOrderExcelExport({ rootGetters }, request) {
      return axios
        .post('closetHighway/QuickOrderExcelExport', { ...request }, { responseType: 'blob' })
        .then((response) => {
          if (response.status === 200) {
            return Promise.resolve(response.data)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CabinetExport({ rootGetters }, request) {
      return axios
        .post(
          'closetHighway/CabinetExport',
          {
            ...request
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          if (response.status === 200) {
            return Promise.resolve(response.data)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CabinetImport({ commit, rootGetters }, request) {
      const formData = new FormData()
      formData.append('RawBoardExcelFile', request.RawBoardExcelFile)
      return axios
        .post('closetHighway/CabinetImport', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //删除附件
    DeleQuickProductOrderDemandFile({ rootGetters }, request) {
      return axios
        .post('DeleQuickProductOrderDemandFile', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //制造商 拆單附件上傳完成
    SetQuickProductOrderPrice({ rootGetters }, request) {
      let formData = new FormData()
      formData.append('OrderID', request.OrderID)
      request.DemandXmls.forEach((demand, index) => {
        formData.append(`DemandXmls[${index}].DemandID`, demand.DemandID)
        formData.append(`DemandXmls[${index}].ManufacturerProcessFile`, demand.ManufacturerProcessFile)
        formData.append(`DemandXmls[${index}].PackageRemark`, demand.PackageRemark)
        formData.append(`DemandXmls[${index}].IsFileExist`, demand.IsFileExist)
      })

      return axios
        .post('SetQuickProductOrderPrice', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -5) {
            return Promise.reject({
              message: '訂單內有排除的封邊類型',
              data: {
                edgeBandingExcludeBoards: response.data.Values.EdgeBandingExcludeBoards
              }
            })
          } else if (response.data.Values.ReqInt === -4) {
            return Promise.reject({
              message: '缺少的原料板',
              data: {
                lackRawBoards: response.data.Values.OrderPriceLackRawBoards
              }
            })
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject({
              message: '缺少物料',
              data: {
                lackBoards: response.data.Values.LackBoards,
                lackMetals: response.data.Values.LackMetals
              }
            })
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('XML格式錯誤')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },

    //制造商获取商城生产订单
    GetQuickProductOrderList({ rootGetters }, request) {
      return axios
        .post('/GetQuickProductOrderList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //制造商获取商城生产订单明细
    ManufacturerOrderRead({ rootGetters }, request) {
      return axios
        .post('/ManufacturerOrderRead', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //将快客订单添加到生产订单
    AddQuickOrderInfo({ rootGetters }, request) {
      return axios
        .post('closetHighway/AddQuickOrderInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },

    //获取公司部门接口
    GetCompaniesDeptList({ rootGetters }, request) {
      return axios
        .post(process.env.BASE_URL + 'api/Permissions/GetCompaniesDeptList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 获取后台人员订单
    GetQuickBusinessOrderList({ rootGetters }, request) {
      return axios
        .post('closetHighway/GetQuickBusinessOrderList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 获取后台人员订单详情
    GetQuickBusinessOrderDetail({ rootGetters }, request) {
      return axios
        .post('closetHighway/GetQuickBusinessOrderDetail', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ReportDeliveryOrderExcel({ rootGetters }, request) {
      return axios
        .post('closethighway/ReportDeliveryOrderExcel', { ...request }, { responseType: 'blob' })
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ModifyQuickBusinessOrder({ rootGetters }, request) {
      return axios
        .post('closetHighway/ModifyQuickBusinessOrder', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    UpdateQuickBusinessOrder({ rootGetters }, request) {
      return axios
        .post('closetHighway/UpdateQuickBusinessOrder', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 获取热销产品列表
    GetQuickHotCommodityList({ rootGetters }, request) {
      return axios
        .post('closetHighway/GetQuickHotCommodityList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 添加热销产品柜
    AddQuickHotCommodityInfo({ rootGetters }, request) {
      return axios
        .post('closetHighway/AddQuickHotCommodityInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 编辑热销产品柜
    EditQuickHotCommodityInfo({ rootGetters }, request) {
      return axios
        .post('closetHighway/EditQuickHotCommodityInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //删除热销产品柜
    DeleQuickHotCommodityInfo({ rootGetters }, request) {
      return axios
        .post('closetHighway/DeleQuickHotCommodityInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 获取快客柜滚动条信息
    GetCabinetTitleInfoList({ rootGetters }, request) {
      return axios
        .post('closetHighway/GetCabinetTitleInfoList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 新增快客柜滚动条信息
    AddCabinetTitleInfo({ rootGetters }, request) {
      return axios
        .post('closetHighway/AddCabinetTitleInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 修改快客柜滚动条信息
    ModifyCabinetTitleInfo({ rootGetters }, request) {
      return axios
        .post('closetHighway/ModifyCabinetTitleInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 删除快客柜滚动条信息
    DeleCabinetTitleInfo({ rootGetters }, request) {
      return axios
        .post('closetHighway/DeleCabinetTitleInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 设置工班排班表
    SetScheduleList({ rootGetters }, request) {
      return axios
        .post('closetHighway/SetWorkingScheduleList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 获取工班排班表
    GetScheduleList({ rootGetters }, request) {
      return axios
        .post('closetHighway/GetWorkingScheduleList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //获取子柜体列表
    GetQuickSubTypeGroupInfoList({ rootGetters }, request) {
      return axios
        .post('closetHighway/GetQuickSubTypeGroupInfoList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //查詢櫃子信息
    GetCabinetInfo({ rootGetters }, request) {
      return axios
        .post('closetHighway/GetCabinetInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    AddQuickSubTypeGroupInfo({ rootGetters }, request) {
      return axios
        .post('closetHighway/AddQuickSubTypeGroupInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SetQuickSubTypeGroupInfo({ rootGetters }, request) {
      return axios
        .post('closetHighway/SetQuickSubTypeGroupInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DeleQuickSubTypeGroupInfo({ rootGetters }, request) {
      return axios
        .post('closetHighway/DeleQuickSubTypeGroupInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    QuickOrderChangeStatus({ rootGetters }, request) {
      return axios
        .post('closetHighway/QuickOrderChangeStatus', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetQuickSubTypeGroupInfoCabinet({ rootGetters }, request) {
      return axios
        .post('closetHighway/GetQuickSubTypeGroupInfoCabinet', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetPictureData({ commit, rootGetters }, request) {
      return axios
        .get(`${process.env.ASSEMBLY_FILE_URL}/api/file/GetPictureData?imagePath=${request.imagePath}`)
        .then((response) => {
          if (response.data.code === 200) {
            return response.data
          } else {
            throw new Error('發生錯誤')
          }
        })
        .catch((error) => {
          throw error
        })
    },
    GetCabinetUpdateList({ rootGetters }, request) {
      return axios
        .post('closetHighway/GetCabinetUpdateList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    AddAotuQuickTitleInfo({ rootGetters }, request) {
      return axios
        .post('closetHighway/AddAotuQuickTitleInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    QuickBoardBrandList({ rootGetters }, request) {
      return axios
        .post('closetHighway/QuickBoardBrandList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    QuickBoardBrandDelete({ rootGetters }, request) {
      return axios
        .post('closetHighway/QuickBoardBrandDelete', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    QuickBoardBrandRead({ rootGetters }, request) {
      return axios
        .post('closetHighway/QuickBoardBrandRead', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    QuickBoardBrandAdd({ rootGetters }, request) {
      return axios
        .post('closetHighway/QuickBoardBrandAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('品牌名稱重複')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    QuickBoardBrandModify({ rootGetters }, request) {
      return axios
        .post('closetHighway/QuickBoardBrandModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('品牌名稱重複')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('品牌不存在')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    QuickBoardBrandBoardModify({ rootGetters }, request) {
      return axios
        .post('closetHighway/QuickBoardBrandBoardModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('板材內容重複')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    QuickBoardListByBrand({ rootGetters }, request) {
      return axios
        .post('closetHighway/QuickBoardListByBrand', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetQuickDesignerList({ rootGetters }, request) {
      return axios
        .post('closethighway/GetQuickDesignerList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SetQuickDesignerState({ rootGetters }, request) {
      return axios
        .post('closethighway/SetQuickDesignerState', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetQuickDesignProjectRecordList({ rootGetters }, request) {
      return axios
        .post('closethighway/GetQuickDesignProjectRecordList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    AssignQuickDesignProject({ rootGetters }, request) {
      return axios
        .post('closethighway/AssignQuickDesignProject', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CompleteQuickDesignProject({ rootGetters }, request) {
      return axios
        .post('closethighway/CompleteQuickDesignProject', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetQuickDesignProjectInquiryingRecordList({ rootGetters }, request) {
      return axios
        .post('closethighway/GetQuickDesignProjectInquiryingRecordList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ReplyQuickDesignProject({ rootGetters }, request) {
      return axios
        .post('closethighway/ReplyQuickDesignProject', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetQuickDesignProjectHistoryRecordList({ rootGetters }, request) {
      return axios
        .post('closethighway/GetQuickDesignProjectHistoryRecordList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    StartQuickDesignProject({ rootGetters }, request) {
      return axios
        .post('closethighway/StartQuickDesignProject', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExamineQuickAbnormalAppendDetail({ rootGetters }, request) {
      return axios
        .post('closethighway/ExamineQuickAbnormalAppendDetail', {
          ...request
        })
        .then((response) => {
          if (response.data.Values) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //获取异况和追加单详情
    QuickAbnormalAppendDetail({ rootGetters }, request) {
      return axios
        .post('closethighway/QuickAbnormalAppendDetail', {
          ...request
        })
        .then((response) => {
          if (response.data.Values) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //修改异况和追加单信息
    ModifyAbnoamalAppend({ rootGetters }, request) {
      return axios
        .post('closethighway/ModifyAbnoamalAppend', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //新增异况和追加单信息
    AbnormalAppendAdd({ rootGetters }, request) {
      return axios
        .post('closethighway/AbnormalAppendAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //将新增异况的快客订单添加到生产订单
    AddQuickOrderAbnormalInfo({ rootGetters }, request) {
      return axios
        .post('closethighway/AddQuickOrderAbnormalInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //删除异况和追加单信息
    DelAbnormalAppend({ rootGetters }, request) {
      return axios
        .post('closethighway/DelAbnormalAppend', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //獲取异况和追加单列表
    QuickAbnormalAppendExamine({ rootGetters }, request) {
      return axios
        .post('closethighway/QuickAbnormalAppendExamine', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //审核异况和追加单
    ModifyAbnoamalAppendExamine({ rootGetters }, request) {
      return axios
        .post('closethighway/ModifyAbnoamalAppendExamine', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    LockUploadFile({ rootGetters }, request) {
      return axios
        .post('LockUploadFile', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    QuickDesignCasesUploadFile({ rootGetters }, request) {
      return axios
        .post('QuickDesignCasesUploadFile', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //责任归属人信息
    AccountAbilityInfo({ rootGetters }, request) {
      return axios
        .post('closethighway/AccountAbilityInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //导出异况或追加单的信息
    AbnormalAppendExcel({ rootGetters }, request) {
      return axios
        .post('closethighway/AbnormalAppendExcel', { ...request }, { responseType: 'blob' })
        .then((response) => {
          if (response.status === 200) {
            return Promise.resolve(response.data)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    }
    // #endregionPOST
  }
}
