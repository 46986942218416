
import { Notify } from 'quasar'
import { transRoutes } from 'src/router/index'

export default ({ app, router, store, Vue }) => {
   router.beforeEach((to, from, next) => {
     store.dispatch('UserStore/checkLoginUserInfo').then((res) => {
      if (store.getters['UserStore/getUserIsLogin'] === true) {
        if (store.getters['UserStore/getLoginUserIsTermsAgreed'] !== true) {
          if (to.name !== 'terms') {
            return next({
              name: 'terms',
              query: {
                redirect: to.fullPath
              }
            })
          }
        }
      }

      if (to.meta && to.meta.requiredLogin === true) {
        if (store.getters['UserStore/getUserIsLogin'] !== true) {
          Notify.create({
            type: 'negative',
            message: '請先登入'
          })

          next({
            name: 'home',
            query: {
              action: 'login',
              redirect: to.fullPath
            }
          })
          return
        }
        // else if (to.meta.limitAuth) {
        //   for (let i = 0; i < to.meta.limitAuth.length; i++) {
        //     let auth = to.meta.limitAuth[i]

        //     if (store.getters['UserStore/getLoginUserInfo'].UserPermission[auth] !== true) {
        //       Notify.create({
        //         type: 'negative',
        //         message: '帳號權限不足'
        //       })

        //       next({
        //         name: 'home',
        //         query: {
        //           action: 'login',
        //           redirect: to.fullPath
        //         }
        //       })
        //       return
        //     }
        //   }
        // }
      }
      const dynamicRoutes = transRoutes(store.getters['UserStore/getRouterList'])
      // console.log(dynamicRoutes, 'dynamicRoutes')
      if (Array.isArray(dynamicRoutes)) {
        dynamicRoutes.forEach((route) => {
          const routeExists = router.getRoutes().some((route) => route.path === '/dashboard')
          if (!routeExists) {
            const staticRoute = [
              { path: '', name: 'backend_home', component: () => import('pages/Backend/Index.vue') },
              {
                path: 'user',
                component: { render: (h) => h('router-view') },
                children: [
                  {
                    path: '',
                    name: 'backend_user',
                    component: () => import('pages/Backend/User/User.vue'),
                    meta: { requiredLogin: true }
                  }
                ]
              }
            ]
            route.children.push(...staticRoute)
            router.addRoute(route)
            next(to.params.pathMatch)
          } else {
          }
        })
      } else {
        console.error('动态路由不是有效的数组')
      }
      next()
    })
  })
}
