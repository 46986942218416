const routes = [
  {
    path: '/',
    component: () => import('layouts/Frontend.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('pages/Frontend/Index.vue')
      },
      {
        path: 'company-register',
        name: 'company-register',
        component: () => import('pages/Frontend/Index.vue')
      },
      {
        path: 'terms',
        name: 'terms',
        component: () => import('pages/Frontend/Index.vue')
      },
      {
        path: 'news',
        name: 'news',
        component: () => import('pages/Frontend/News/News.vue')
      },
      {
        path: 'news_detail/:newsGuid',
        name: 'news_detail',
        component: () => import('pages/Frontend/News/NewsDetail.vue')
      },
      {
        path: 'about-us',
        name: 'about-us',
        component: () => import('pages/Frontend/AboutUs.vue')
      },
      {
        path: 'product_introduction/design',
        name: 'product_introduction.design',
        component: () => import('pages/Frontend/Production/ProductIntro_Design.vue')
      },
      {
        path: 'product_introduction/mes',
        name: 'product_introduction.mes',
        component: () => import('pages/Frontend/Production/ProductIntro_MES.vue')
      },
      {
        path: 'product_introduction/wil',
        name: 'product_introduction.wil',
        component: () => import('pages/Frontend/Production/ProductIntro_WIL.vue')
      },
      {
        path: 'board-portal',
        name: 'board-portal',
        component: () => import('pages/Frontend/Board/BoardIntro.vue')
      },
      {
        path: 'boards/:boardType/:page',
        name: 'boards',
        component: () => import('pages/Frontend/Board/BoardList.vue')
      },
      {
        path: '/board/:boardType/:boardSN',
        name: 'boardDetail',
        component: () => import('pages/Frontend/Board/BoardDetail.vue')
      },
      {
        path: 'course',
        name: 'course',
        component: () => import('pages/Frontend/Course/Course.vue')
      },
      {
        path: 'course_list/:playListGuid',
        name: 'course_list',
        component: () => import('pages/Frontend/Course/CourseList.vue')
      },
      {
        path: 'course_video/:videoGuid',
        name: 'course_video',
        component: () => import('pages/Frontend/Course/CourseVideo.vue')
      },
      {
        path: 'contact_us',
        name: 'contact_us',
        component: () => import('pages/Frontend/ContactUs.vue')
      },
      {
        path: 'design_works',
        name: 'design_works',
        component: () => import('pages/Frontend/DesignWork/DesignWorks.vue')
      },
      {
        path: 'design_work_detail/:caseGuid',
        name: 'design_work_detail',
        component: () => import('pages/Frontend/DesignWork/DesignWorkDetail.vue')
      },
      {
        path: 'activities',
        name: 'activities',
        component: () => import('pages/Frontend/Activity/Activities.vue')
      },
      {
        path: 'activity_detail/:activityGuid',
        name: 'activity_detail',
        component: () => import('pages/Frontend/Activity/ActivityDetail.vue')
      },
      {
        path: 'activity_apply/:activityGuid',
        name: 'activity_apply',
        component: () => import('pages/Frontend/Activity/ActivityApply.vue')
      },
      {
        path: 'mobile-validation',
        name: 'mobile-validation',
        component: () => import('pages/Frontend/MobileValidation.vue')
      },
      {
        path: 'sign-up-validation',
        name: 'sign-up-validation',
        component: () => import('pages/Frontend/SignUpValidation.vue')
      },
      {
        path: 'save-design-order',
        name: 'save-design-order',
        component: () => import('pages/Frontend/DesignOrder.vue')
      },
      {
        path: 'whole-house-quote',
        name: 'whote-house-quote',
        component: () => import('pages/Frontend/WholeHouseQuote.vue'),
        meta: { requiredLogin: true, limitAuth: ['Designer'] }
      }
    ]
  }
] 

// Always leave this as last one 如果当前运行模式不是服务器端渲染（SSR），那么就将所有未匹配的路由重定向到主页，这不是无解吗
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    redirect: { name: 'home' }
  })
}

export default routes
